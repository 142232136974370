<template>
  <div>
    <nav>
      <TheHeader />
    </nav>
    <main>
      <slot />
    </main>
      <TheFooter />

      

  </div>


   

</template>